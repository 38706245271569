var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('div', {
    staticClass: "row justify-content-md-center"
  }, [_c('div', {
    staticClass: "col-xxl-5 col-xl-6 col-lg-7 col-md-8"
  }, [_c('div', {
    staticClass: "altEmail-card"
  }, [_c('div', {
    staticClass: "altEmail-head text-center"
  }, [_c('h1', [_vm._v("Alternative Email Address")]), _c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.addEmail",
      modifiers: {
        "addEmail": true
      }
    }],
    staticClass: "addemail-btn"
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })]), _c('p', [_vm._v("Add additional emails that you would also like to recieve emails.")])], 1), _c('div', {
    staticClass: "altEmail-body"
  }, [_c('ul', [_c('li', [_vm._v(" phamal@cloudtechservices.com "), _c('div', {
    staticClass: "deleteEmail"
  }, [_c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "href": "#",
      "title": "Delete"
    }
  }, [_c('i', {
    staticClass: "far fa-trash-alt"
  })])])]), _c('li', [_vm._v(" adminems@cts.com "), _c('div', {
    staticClass: "deleteEmail"
  }, [_c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "href": "#",
      "title": "Delete"
    }
  }, [_c('i', {
    staticClass: "far fa-trash-alt"
  })])])]), _c('li', [_vm._v(" imdineshchand@gmail.com "), _c('div', {
    staticClass: "deleteEmail"
  }, [_c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "href": "#",
      "title": "Delete"
    }
  }, [_c('i', {
    staticClass: "far fa-trash-alt"
  })])])]), _c('li', [_vm._v(" adminems@cts.com "), _c('div', {
    staticClass: "deleteEmail"
  }, [_c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "href": "#",
      "title": "Delete"
    }
  }, [_c('i', {
    staticClass: "far fa-trash-alt"
  })])])]), _c('li', [_vm._v(" saileshpant12@gmail.com "), _c('div', {
    staticClass: "deleteEmail"
  }, [_c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "href": "#",
      "title": "Delete"
    }
  }, [_c('i', {
    staticClass: "far fa-trash-alt"
  })])])]), _c('li', [_vm._v(" joinmesonu12@mailinator.com "), _c('div', {
    staticClass: "deleteEmail"
  }, [_c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "href": "#",
      "title": "Delete"
    }
  }, [_c('i', {
    staticClass: "far fa-trash-alt"
  })])])])])])])])])], 1), _c('b-modal', {
    attrs: {
      "id": "addEmail",
      "centered": "",
      "size": "md"
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "addEmail-header"
        }, [_c('h4', [_vm._v("Add Email Address")]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": function click($event) {
              _vm.show = false;
            }
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "addEmail-form"
  }, [_c('form', {
    attrs: {
      "action": ""
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Email Address "), _c('span', {
    staticClass: "required-indc"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "addEmail-field",
    attrs: {
      "type": "Email",
      "placeholder": "Enter Alternate Email Address"
    }
  })]), _c('div', {
    staticClass: "form-group text-center mb-3 mt-3"
  }, [_c('b-button', {
    staticClass: "univ-btn"
  }, [_vm._v("Submit")])], 1)])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }