<template>
    <div class="page-wrapper">
        <div class="container-fluid">
            <breadcrumb :items="breadcrumbItems" />
            <div class="row justify-content-md-center">
                <div class="col-xxl-5 col-xl-6 col-lg-7 col-md-8">
                    <div class="altEmail-card">
                        <div class="altEmail-head text-center">
                            <h1>Alternative Email Address</h1>
                            <b-button class="addemail-btn" v-b-modal.addEmail><i class="fas fa-plus"></i></b-button>
                            <p>Add additional emails that you would also like to recieve emails.</p>
                        </div>
                        <div class="altEmail-body">
                            <ul>
                                <li>
                                    phamal@cloudtechservices.com
                                    <div class="deleteEmail">
                                        <a href="#" v-b-tooltip.hover title="Delete"><i class="far fa-trash-alt"></i></a>
                                    </div>    
                                </li>
                                <li>
                                    adminems@cts.com
                                    <div class="deleteEmail">
                                        <a href="#" v-b-tooltip.hover title="Delete"><i class="far fa-trash-alt"></i></a>
                                    </div>    
                                </li>
                                <li>
                                    imdineshchand@gmail.com
                                    <div class="deleteEmail">
                                        <a href="#" v-b-tooltip.hover title="Delete"><i class="far fa-trash-alt"></i></a>
                                    </div>    
                                </li>
                                <li>
                                    adminems@cts.com
                                    <div class="deleteEmail">
                                        <a href="#" v-b-tooltip.hover title="Delete"><i class="far fa-trash-alt"></i></a>
                                    </div>    
                                </li>
                                <li>
                                    saileshpant12@gmail.com
                                    <div class="deleteEmail">
                                        <a href="#" v-b-tooltip.hover title="Delete"><i class="far fa-trash-alt"></i></a>
                                    </div>    
                                </li>
                                <li>
                                    joinmesonu12@mailinator.com
                                    <div class="deleteEmail">
                                        <a href="#" v-b-tooltip.hover title="Delete"><i class="far fa-trash-alt"></i></a>
                                    </div>    
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Email Add Pop up -->
        <b-modal id="addEmail" centered size="md"  v-model="show">
            <template #modal-header>
                <div class="addEmail-header">
                    <h4>Add Email Address</h4>

                    <b-button variant="close-btn" @click="show=false">
                        <i class="fas fa-times"></i>
                    </b-button> 
                </div>
            </template>
            <b-container fluid>
                <div class="addEmail-form">
                    <form action="">
                        <div class="form-group">
                            <label for="">Email Address <span class="required-indc">*</span></label>
                            <input type="Email" placeholder="Enter Alternate Email Address" class="addEmail-field">
                        </div>
                         <div class="form-group text-center mb-3 mt-3">
                            <b-button class="univ-btn">Submit</b-button>
                        </div>
                    </form>
                </div>
            </b-container>

            
        </b-modal>
    </div>
</template>
<script>
export default {
    name: 'AlternativeEmail',
    data() {
        return {
            show: false,
            variants: ['close-btn'],
            breadcrumbItems: [{ title: "Alternative Emails", active: true }],
        }
    }
}
</script>
<style>
.altEmail-card{
    background: #fff;
    box-shadow: 0 2px 15px rgb(216 227 235 / 33%);
    border-radius: 10px;
    padding: 2rem;
    margin-top: 4rem;
}
.altEmail-head h1{
    font-size: 22px;
    font-weight: 700;
    color: #2c3f58;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 3px;
    margin-right: 10px;
}
.altEmail-head p{
    color: #778699;
    font-size: 14px;
    margin-top: 1rem;
}
.altEmail-head{
    margin-bottom: 2rem;
}
.addemail-btn, .addemail-btn:active, .addemail-btn:focus{
    background: #3BB54A;
    border: 0;
    border-radius: 50px;
    font-size: 12px;
    padding: 6px 10px;
    display: inline-block;
    vertical-align: middle;
    box-shadow: inherit;
}
.addemail-btn:hover{
    background: #33923f;
}
.altEmail-body ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
.altEmail-body ul li{
        display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    font-size: 15px;
    margin: 10px 0;
    border-radius: 8px;    
    word-break: break-word;

}
.altEmail-body ul li:nth-child(odd){
    background: rgb(41 188 255 / 10%);
}
.altEmail-body ul li:nth-child(even){
    background: #FAFBFD;
}
.deleteEmail a{
    background: #EA584E;
    color: #fff;
    display: block;
    border-radius: 6px;
    font-size: 12px;
    padding: 6px 12px;
}

/* Email Add Pop up Css */
#addEmail .modal-content{
    border: 0px;
    border-radius: 10px;
}
#addEmail .modal-header{
    justify-content: center;
    border: 0;
    padding: 25px 0;
    position: relative;
}
#addEmail .modal-footer{
    display: none;
}
.addEmail-header h4{
    font-size: 20px;
    color: #2C3F58;
    font-weight: 600;
    margin: 0;
}
.addEmail-header button{
    position: absolute;
    top: -15px;
    right: -15px;
    background: #fff;
    color: #000;
    border: 0;
    box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
    border-radius: 60px;
    font-size: 18px;
    width: 45px;
    height: 45px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.addEmail-form label{
    color: #5e5e5e;
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 500;
}
#addEmail .modal-footer .univ-btn{
    max-width: 300px;
}
.univ-btn{
    background: linear-gradient(#0cf,#1597b8 49%,#0567bc);
    border-radius: 6px;
    color: #fff;
    outline: none;
    border: 0;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    margin-top: 10px;
    transition: all ease 0.6s;
}
.univ-btn:hover{
    transition: all ease 0.6s;
    box-shadow: 0px 8px 20px -13px rgb(0 0 0 / 69%);
}
.addEmail-field{
    width: 100%;
    border: 1px solid #dce1e7;
    outline: none;
    border-radius: 6px;
    padding: 9px 15px;
    font-size: 14px;    
    transition: all ease 0.6s;
}
.addEmail-field:focus{
    border: 1px solid #03C3EC;
    transition: all ease 0.6s;
}
.required-indc{
    color: #ff0000;
    font-weight: 500;
}
@media(max-width: 1400px){
    .altEmail-card{
        margin-top: 1rem;
    }
    .altEmail-head h1{
        font-size: 20px;
    }
    .addemail-btn, .addemail-btn:active, .addemail-btn:focus{
        font-size: 10px;
        padding: 5px 9px;
    }
    .altEmail-head p, .altEmail-body ul li{
        font-size: 13px;
    }
    .altEmail-body ul li{
        padding: 8px 15px;
    }
}
@media(max-width: 455px){
    .altEmail-head h1{
        font-size: 18px;
    }
    .addemail-btn{
        font-size: 10px;
        padding: 7px 10px;
    }
}
</style>